<template>
  <div>
    <tabs
      v-if="isSearch"
      :delUrl="delUrl"
      @searchData="searchData"
      @resetData="searchData"
      :parentsList="parentsList"
    ></tabs>
    <refreshbtn @refesh="refesh" :isBetWeen="true">
      <template slot="left_btn" v-if="!userInfo.is_outer">
        <el-button size="small" type="success" @click="batchlaunch"
          >批量上线</el-button
        >
        <el-button size="small" type="danger" @click="openSetTags"
          >批量设置标签</el-button
        >
        <el-button size="small" type="success" @click="batchlaunchoff"
          >批量弃用</el-button
        >
        <el-button size="small" type="success" @click="deleteData"
          >一键删除</el-button
        >
      </template>
      <template slot="search-btns">
        <el-button
          title="普通搜索"
          size="small"
          icon="el-icon-search"
          @click="isSearch = !isSearch"
        ></el-button>
      </template>
    </refreshbtn>
    <videoTable
      :headerList="headerList"
      :tableData="tableData"
      @editData="editData"
      :total="total"
      :pages="search.page"
      :pageSize="search.size"
      :delFIle="delUrl"
      :isLook="true"
      :axiosTile="axiosTile"
      :parentsList="parentsList"
      :editUrl="'/api/video/update'"
      :checkUrl="'/api/video/checkRS'"
      :deleteUrl="'/api/video/destroy'"
      ref="table"
      @lookVideo="lookVideo"
      :isSelect="true"
    />
    <videoDialog
      :data="editOpen"
      @clearData="clearData"
      @pushData="updateData"
      :parentsList="parentsList"
      :isLook="true"
      ref="dialog"
    ></videoDialog>
    <lookVideoDialog ref="lookDialog"></lookVideoDialog>
    <!--批量标签话题编辑弹窗-->
    <batch-tags-popup
      ref="batchTagsPopup"
      type="movie"
      @handlerClose="handlerClose"
    ></batch-tags-popup>
  </div>
</template>

<script>
import videoTable from "./components/videoTable";
import videoDialog from "./components/videoDialog";
import tabs from "./components/tabs";
import lookVideoDialog from "./components/lookVideoDialog";
import BatchTagsPopup from "./components/batchTagsPopup";
import API from "@/api";
import local from "@/utils/localStroage.js";

export default {
  name: "videoPage",
  components: {
    videoTable,
    videoDialog,
    tabs,
    lookVideoDialog,
    BatchTagsPopup,
  },
  data() {
    return {
      search: {
        page: 1,
        size: 10,
      },
      axiosTile: "",
      delUrl: "/api/video/destroy",
      editOpen: {},
      total: 0,
      headerList: [
        {
          key: "ID",
          value: "id",
          align: "center",
        },
        {
          key: "UP主",
          value: "vlogger",
          align: "center",
          type: "obj",
          objValue: "account",
        },
        {
          key: "唯一标识",
          value: "hash_id",
          align: "center",
          minWidth: "350",
        },
        {
          key: "文件资源id",
          value: "fs_resource_id",
          align: "center",
          minWidth: "100",
        },
        {
          key: "文件状态",
          value: "fs_resource_status",
          align: "center",
          width: "100",
          dictionary: [
            { key: "Waiting", value: "待检查" },
            { key: "Uploading", value: "文件上传中" },
            { key: "UploadError", value: "文件上传失败" },
            { key: "UploadCompleted", value: "文件上传完成" },
            { key: "FileMerging", value: "文件合并中" },
            { key: "FileConverting", value: "文件转换中" },
            { key: "FileMergeError", value: "文件合并失败" },
            { key: "FileConvertError", value: "文件转换失败" },
            { key: "CompletedFail", value: "处理失败" },
            { key: "CompressCompleted", value: "压缩完成" },
            { key: "Completed", value: "处理完成" },
          ],
          type: "dictionary",
        },
        {
          key: "连载名称",
          value: "sets_id",
          align: "center",
          type: "filter",
        },
        {
          key: "媒体类型",
          value: "media_type",
          align: "center",
          dictionary: [
            { key: 0, value: "视频" },
            { key: 1, value: "图文" },
          ],
          type: "dictionary",
        },
        {
          key: "视频标题",
          value: "title",
          minWidth: "350",
        },
        {
          key: "竖版封面",
          value: "vertical_cover",
          type: "image",
          align: "center",
        },
        {
          key: "视频封面",
          value: "cover_image",
          type: "image",
          align: "center",
        },
        {
          key: "视频图册展示",
          value: "pics",
          type: "image",
          align: "center",
          width: "120",
        },
        // {
        //   key: "打点数据",
        //   value: "points",
        //   type: "handle",
        //   minWidth:'160'
        // },
        {
          key: "是否精品",
          value: "is_high_quality",
          type: "Boolean",
          align: "center",
        },
        {
          key: "质量打分",
          value: "quality_score",
          align: "center",
        },
        {
          key: "正片时长",
          value: "length",
          type: "sfmTime",
          align: "center",
        },
        {
          key: "预览时长",
          value: "preview_length",
          type: "sfmTime",
          align: "center",
        },
        {
          key: "宽度 px",
          value: "width",
          type: "unit",
          unit: "px",
          align: "center",
        },
        {
          key: "高度 px",
          value: "height",
          type: "unit",
          unit: "px",
          align: "center",
        },
        {
          key: "是否竖版",
          value: "is_vertical",
          type: "Boolean",
          align: "center",
        },
        {
          key: "480p地址",
          value: "src_480",
          align: "center",
          minWidth: "250",
          type: "src",
        },
        {
          key: "480P文件大小",
          value: "file_size_480",
          type: "size",
          align: "center",
          width: "120",
        },
        {
          key: "视频预览地址",
          value: "preview_src",
          align: "center",
          minWidth: "250",
          type: "src",
        },
        {
          key: "标签",
          value: "tags_text",
          minWidth: "160",
          align: "center",
        },
        {
          key: "来源产品",
          value: "from_pro_id",
          align: "center",
        },
        {
          key: "数据版本",
          value: "data_version",
          align: "center",
        },
        {
          key: "编辑者",
          value: "edit_by",
          align: "center",
          minWidth: "100",
        },
        // {
        //   key: "上传者",
        //   value: "terminal_cdo",
        //   align: "center",
        //   fixed: true,
        // },
        {
          key: "弃用理由",
          value: "refuse_reason",
          align: "center",
          width: "100",
        },
        {
          key: "状态",
          value: "status",
          type: "dictionary",
          align: "center",
          width: "100",
          dictionary: [
            {
              key: 0,
              value: "准备中",
            },
            {
              key: 7,
              value: "弃用",
            },
            {
              key: 10,
              value: "完成&可使用",
            },
          ],
          fixed: true,
        },
      ],
      tableData: [],
      parentsList: [],
      isSearch: false,
      isRefesh: true,
      userInfo: undefined,
    };
  },
  created() {
    this.userInfo = local.get("userInfo");
    if (!this.userInfo.is_outer) {
      this.headerList.splice(22, 0, {
        key: "入库时间",
        value: "create_time",
        type: "time",
        align: "center",
        width: "160",
      });
      this.headerList.splice(23, 0, {
        key: "更新时间",
        value: "update_time",
        type: "time",
        align: "center",
        width: "160",
      });
      this.headerList.splice(27, 0, {
        key: "上传者",
        value: "terminal_cdo",
        align: "center",
        fixed: true,
      });
    }
    let listRule = "/api/video/list";
    let obj = JSON.parse(
      JSON.parse(JSON.stringify(localStorage.getItem("btnPower")))
    );
    if (obj[listRule]) {
      this.isRefesh = true;
    } else {
      this.isRefesh = false;
    }
    this.getVideoList();
  },
  methods: {
    // 批量上线
    async batchlaunch() {
      if (!this.$refs.table.multipleSelection.length) {
        this.$message({ type: "warning", message: "请选择要上线的数据" });
        return false;
      }
      let ids = [];
      this.$refs.table.multipleSelection.forEach((item) => {
        ids.push(item.id);
      });
      const { msg, code, data } = await API.onlineVideo({ ids: ids });
      if (!code) {
        this.$message.success(
          "成功上线" + data.success + "条数据，失败" + data.failure + "条"
        );
        this.getVideoList(this.search.page);
      } else {
        this.$message.error(msg);
      }
    },
    // 批量设置标签
    async openSetTags() {
      if (!this.$refs.table.multipleSelection.length) {
        this.$message({
          type: "warning",
          message: "请选择要批量设置标签的数据",
        });
        return false;
      }
      let ids = [];
      this.$refs.table.multipleSelection.forEach((item) => {
        ids.push(item.id);
      });
      this.$refs.batchTagsPopup.open(ids);
    },
    // 批量设置标签
    async batchSetTags() {
      console.log("设置标签");
      let ids = [];
      this.$refs.table.multipleSelection.forEach((item) => {
        ids.push(item.id);
      });
      // const { msg, code, data } = await API.onlineVideo({ ids: ids });
      // if (!code) {
      //   this.$message.success(
      //     "成功上线" + data.success + "条数据，失败" + data.failure + "条"
      //   );
      //   this.getVideoList(this.search.page);
      // } else {
      //   this.$message.error(msg);
      // }
    },
    // 批量下线
    async batchlaunchoff() {
      if (!this.$refs.table.multipleSelection.length) {
        this.$message({ type: "warning", message: "请选择要弃用的数据" });
        return false;
      }
      let ids = [];
      this.$refs.table.multipleSelection.forEach((item) => {
        ids.push(item.id);
      });
      this.$prompt("请输入弃用理由", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.handlebatchlaunchoff(ids, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 批量下线
    async handlebatchlaunchoff(ids, refuse_reason) {
      const { msg, code, data } = await API.offlineVideo({
        ids: ids,
        refuse_reason: refuse_reason,
      });
      if (!code) {
        this.$message.success(
          "成功弃用" + data.success + "条数据，失败" + data.failure + "条"
        );
        this.getVideoList(this.search.page);
      } else {
        this.$message.error(msg);
      }
    },
    refesh() {
      this.isRefesh ? this.getVideoList() : "";
    },
    // 获取列表数据
    async getSetsList() {
      // 获取视频列表
      const { data, code } = await API.getVideoList(
        this.search,
        this.axiosTile
      );
      if (!code) {
        this.tableData = data.data;
        this.total = data.pagination.count;
        this.$refs.table.loading = false;
      } else {
        this.tableData = [];
        this.total = 0;
        this.$refs.table.loading = false;
      }
    },
    clearData() {
      this.$refs.dialog.openDialog = false;
      this.editOpen = {};
    },
    async updateData(data) {
      const { code, msg } = await API.updateVideoDatav(data);
      if (!code) {
        this.$message.success("编辑视频成功");
        this.getVideoList(this.search.page);
        this.$refs.dialog.loading = false;
        this.$refs.dialog.openDialog = false;
        this.editOpen = {};
      } else {
        this.$message.error(msg);
        this.$refs.dialog.loading = false;
        this.$refs.dialog.openDialog = false;
        this.editOpen = {};
      }
    },
    editData(data) {
      this.editOpen = JSON.parse(JSON.stringify(data));
      this.$refs.dialog.openDialog = true;
      this.$refs.dialog.loadVideo(data.src_480);
      this.$refs.dialog.delUrl = this.delUrl;
      this.$refs.dialog.getTargetTree();
    },
    async deleteData() {
      const { code, msg } = await API.destoryAllVideo({ id: "" });
      if (!code) {
        this.$message.success("一键删除成功");
        this.getVideoList(this.search.page);
        this.$refs.dialog.loading = false;
      } else {
        this.$message.error(msg);
        this.$refs.dialog.loading = false;
      }
    },
    searchData(data) {
      this.search = { page: 1, size: 10 };
      if (this.isRefesh) {
        data
          ? (this.search = Object.assign(this.search, data))
          : (this.search = { page: 1, size: 10 });
        this.getVideoList();
      }
    },
    // 获取连载视频--连载视频弹框父级字段  视频管理--连载名称字段
    async getVideoList(page) {
      this.search.page = page || 1;
      this.isRefesh
        ? this.$nextTick(() => {
            this.$refs.table.loading = true;
          })
        : "";
      await API.getVideoList({ page: 1, size: 9999 }, "/sets").then((res) => {
        if (!res.code && res.data.data.length) {
          this.parentsList = [{ id: 0, title: "无" }].concat(res.data.data);
        } else {
          this.parentsList = [{ id: 0, title: "无" }];
        }

        this.isRefesh ? this.getSetsList() : "";
      });
    },
    lookVideo(data) {
      this.$refs.lookDialog.openlookDialog = true;
      this.$refs.lookDialog.loadVideo(data.src_480);
    },
    async changeSize(pageSize) {
      this.search.page = 1;
      this.search.size = pageSize;
      this.getSetsList();
    },
    handlerClose() {
      this.getSetsList();
    },
  },
};
</script>

<style scoped></style>
